export class Dog {
  constructor(opts = undefined) {
    if (opts !== null) {
      this._id = opts._id && opts._id;
      this.formalName = opts.formalName && opts.formalName;
      this.callName = opts.callName && opts.callName;
      this.breed = opts.breed && opts.breed;
      this.dob = opts.dob && opts.dob;
      this.heightAtWithers = opts.heightAtWithers && opts.heightAtWithers;
      this.jumpHeight = opts.jumpHeight && opts.jumpHeight;
      this.dogImage = opts.dogImage && opts.dogImage;
      this.registrations = opts.registrations ? opts.registrations : [];
      this.venueJumpHeights = opts.venueJumpHeights
        ? opts.venueJumpHeights
        : [];
      this.titles = opts.titles ? opts.titles : [];
      this.handlers = opts.handlers ? opts.handlers : [];
      this.trials = opts.trials ? opts.trials : [];
      this.trialRuns = opts.trialRuns ? opts.trialRuns : [];
      this.owners = opts.owners ? opts.owners : [];
      this.updatedBy = opts.updatedBy && opts.updatedBy;
      this.status = opts.status && opts.status;
    }
  }

  getPlacedFinishes() {
    // need to cycle through the trial runs and
    // count how many times the dog has finished in the top three
    // add dog as accepted parameter and return should be dog.dog.???
    // return dog.dog.registrations.length;
    return 0;
  }
}
