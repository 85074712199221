<template>
  <el-upload
    :action="uploadURL"
    :headers="{ 'x-auth-token': getUserToken }"
    list-type="picture-card"
    :on-remove="handleRemove"
    :on-change="toggleUpload"
    :class="{ hideUpload: !showUpload }"
    ref="uploadComponent"
    :auto-upload="false"
    :on-error="handleError"
    :on-success="handleSuccess"
    :limit="1"
  >
    <i class="el-icon-plus"></i>
    <template #file="{ file }">
      <div>
        <img class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
        <span class="el-upload-list__item-actions">
          <span class="el-upload-list__item-delete" @click="handleRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </template>
  </el-upload>
</template>

<script>
const envURL = process.env.VUE_APP_ENVURL;

export default {
  emits: ["imageURL"],
  data() {
    return {
      showUpload: true,
      saveFile: "",
      uploadURL: `${envURL}/api/upload`,
    };
  },
  computed: {
    getUserToken() {
      const tokens = this.$store.getters["user/getUserTokens"];
      return tokens.idToken;
    },
  },
  methods: {
    handleRemove(file) {
      const uploadFileList = this.$refs.uploadComponent.uploadFiles;

      uploadFileList.splice(uploadFileList.indexOf(file), 1);

      this.showUpload = true;
    },
    hasImageToSave() {
      return this.$refs.uploadComponent.uploadFiles.length > 0 ? true : false;
    },
    handleSaveImage() {
      this.$refs.uploadComponent.submit();
    },
    handleSuccess(response) {
      this.$emit("imageURL", response.filename);
      this.$refs.uploadComponent.clearFiles();
    },
    handleError(err, file, fileList) {
      this.$message({
        showClose: true,
        message: "Error uploading image, please try again.",
        type: "error",
      });
      console.log("err: ", err);
      console.log("file: ", file);
      console.log("fileList: ", fileList);
    },
    toggleUpload() {
      this.showUpload = !this.showUpload;
    },
  },
};
</script>

<style lang="scss" scoped>
.image-preview {
  width: 100%;
  height: auto;
  text-align: center;

  img {
    width: 200px;
    height: auto;
  }
}

.el-upload-list--picture-card .el-upload-list__item {
  width: 200px;
  height: auto;
  object-fit: contain;
}
</style>
