export const sanctionBodies = [
  "AKC",
  "ASCA",
  "Cynosports",
  "CPE",
  "DOCNA",
  "NADAC",
  "TDAA",
  "UKI",
  "USDAA",
  "Not Sanctioned",
];

export const eventTypes = [
  "AKC Trial",
  "Fun Run",
  "Regional Championship",
  "Seminar",
  "Seminar with Match",
  "Titling Classes Only",
  "Tournament Classes Only",
  "Titling Event with Tournaments & JHP Classes",
  "Titling Event with Tournament Classes",
  "Title Mania",
];

export const runningSurfaces = [
  "Carpeting",
  "Field Turf",
  "Foam Mats",
  "Grass",
  "Indoor Artficial Turf",
  "Outdoor Artificial Turf",
  "Packed Dirt",
  "Padded Matting",
  "Rubber",
  "Rubber Mats",
];

export const contactSurfaces = ["Non-Rubber", "Rubber", "Rubber Matting"];

export const outsideIndoors = ["Outside", "Indoors", "Mixed"];

export const jumpHeights = [
  "2",
  "4",
  "6",
  "8",
  "10",
  "12",
  "14",
  "16",
  "20",
  "22",
  "24",
];

export const runType = ["Single Run", "Double Run"];

export const trialLevels = [
  "Advanced",
  "Beginners",
  "Elementary",
  "Excellent",
  "Intermediate",
  "Level 1",
  "Level 2",
  "Level 3",
  "Performance I",
  "Performance II",
  "Performance III",
  "Premier",
  "Starters",
  "Masters",
  "Novice",
  "Senior",
  "Veterans",
  "Local Qualifier",
];

export const trialClasses = [
  { name: "biathlon", label: "Biathlon" },
  {
    name: "biathlonMCPerformanceJumpers",
    label: "Biathlon MC Performance Jumpers",
  },
  { name: "biathlonMCJumpers", label: "Biathlon MC Jumpers" },
  { name: "biathlonMCStandardAgility", label: "Biathlon MC Standard Agility" },
  {
    name: "biathlonMCPerfomanceStandard",
    label: "Biathlon MC Performance Standard",
  },
  { name: "fast", label: "FAST" },
  { name: "gamblers", label: "Gamblers" },
  { name: "grandPrix", label: "Grand Prix" },
  { name: "grandPrixOfDogAgility", label: "Grand Prix of Dog Agility" },
  { name: "performanceGrandPrix", label: "Performance Grand Prix" },
  { name: "jackPot", label: "Jack Pot" },
  { name: "joker", label: "Joker" },
  { name: "jumpers", label: "Jumpers" },
  { name: "jumpersWithWeaves", label: "Jumpers With Weaves" },
  { name: "mastersChallengeBiathlon", label: "Masters Challenge Biathlon" },
  {
    name: "mastersChallengeBiathlonStandard",
    label: "Masters Challenge Biathlon Standard",
  },
  { name: "powerAndSpeed", label: "Power and Speed" },
  { name: "snooker", label: "Snooker" },
  { name: "speedJumping", label: "Speed Jumping" },
  { name: "standardAgility", label: "Standard Agility" },
  { name: "steeplechase", label: "Steeplechase" },
  { name: "teamSnooker", label: "Team Snooker" },
  { name: "teamPairsRelay", label: "Team Pairs / Relay" },
  { name: "timeToBeat", label: "Time to Beat" },
];

export const AKCClassInfo = {
  trialLevels: [
    "All Levels",
    "Excellent",
    "Master",
    "Novice",
    "Novice A",
    "Novice B",
    "Open",
    "Novice Preferred",
    "Open Preferred",
    "Excellent Preferred",
    "Master Preferred",
    "Preferred Time 2 Beat",
  ],
  trialClasses: [
    { name: "FAST", label: "FAST" },
    { name: "JumperswithWeaves", label: "Jumpers with Weaves" },
    { name: "Standard", label: "Standard" },
    { name: "Time2Beat", label: "Time 2 Beat" },
    { name: "Premier", label: "Premier" },
    {
      name: "MasterPreferredPremierJWW",
      label: "Master Preferred Premier JWW",
    },
    {
      name: "MasterPreferredPremierStandard",
      label: "Master Preferred Premier Standard",
    },
  ],
};

export const CPEClassInfo = {
  trialLevels: [
    "Level 1",
    "Level 2",
    "Level 3",
    "Level 4",
    "Level 5",
    "Level C",
    "Regular",
    "Veterans",
    "Enthusiast",
    "Specialists",
  ],
  trialClasses: [
    { name: "Jumpers", label: "Jumpers" },
    { name: "Wildcard", label: "Wildcard" },
    { name: "Colors", label: "Colors" },
    { name: "Jackpot", label: "Jackpot" },
    { name: "Snooker", label: "Snooker" },
    { name: "FullHouse", label: "FullHouse" },
  ],
};

export const USDAAClassInfo = {
  trialLevels: [
    "Advanced",
    "Beginners",
    "Elementary",
    "Intermediate",
    "Level 1",
    "Level 2",
    "Level 3",
    "Performance I",
    "Performance II",
    "Performance III",
    "Starters",
    "Masters",
    "Novice",
    "Senior",
    "Veterans",
    "Local Qualifier",
  ],
  trialClasses: [
    { name: "biathlon", label: "Biathlon" },
    {
      name: "biathlonMCPerformanceJumpers",
      label: "Biathlon MC Performance Jumpers",
    },
    { name: "biathlonMCJumpers", label: "Biathlon MC Jumpers" },
    {
      name: "biathlonMCStandardAgility",
      label: "Biathlon MC Standard Agility",
    },
    {
      name: "biathlonMCPerfomanceStandard",
      label: "Biathlon MC Performance Standard",
    },
    { name: "fast", label: "FAST" },
    { name: "gamblers", label: "Gamblers" },
    { name: "grandPrix", label: "Grand Prix" },
    { name: "grandPrixOfDogAgility", label: "Grand Prix of Dog Agility" },
    { name: "performanceGrandPrix", label: "Performance Grand Prix" },
    { name: "jackPot", label: "Jack Pot" },
    { name: "joker", label: "Joker" },
    { name: "jumpers", label: "Jumpers" },
    { name: "jumpersWithWeaves", label: "Jumpers With Weaves" },
    { name: "mastersChallengeBiathlon", label: "Masters Challenge Biathlon" },
    {
      name: "mastersChallengeBiathlonStandard",
      label: "Masters Challenge Biathlon Standard",
    },
    { name: "powerAndSpeed", label: "Power and Speed" },
    { name: "snooker", label: "Snooker" },
    { name: "speedJumping", label: "Speed Jumping" },
    { name: "standardAgility", label: "Standard Agility" },
    { name: "steeplechase", label: "Steeplechase" },
    { name: "teamSnooker", label: "Team Snooker" },
    { name: "teamPairsRelay", label: "Team Pairs / Relay" },
    { name: "timeToBeat", label: "Time to Beat" },
  ],
};

export const UKIClassInfo = {
  trialLevels: [
    "Beginner",
    "Novice",
    "Senior",
    "Champion",
    "SS Beginer",
    "SS Novice",
    "SS Senior",
    "SS Champion",
    "Regular vs Select",
    "Masters Series Agility",
    "Masters Series Jumping",
  ],
  trialClasses: [
    { name: "Agility", label: "Agility" },
    { name: "Jumping", label: "Jumping" },
    { name: "Speedstakes1", label: "Speedstakes 1" },
    { name: "Speedstakes2", label: "Speedstakes 2" },
    { name: "Gamblers", label: "Gamblers" },
    { name: "Snookers", label: "Snookers" },
    { name: "PowerandSpeed", label: "Power and Speed" },
    { name: "SnakesandLaders", label: "Snakes and Ladders" },
    { name: "Nursery", label: "Nursery" },
    { name: "Veterans", label: "Veterans" },
  ],
};

export const defaultClassInfo = {
  trialLevels: [
    "Advanced",
    "Beginners",
    "Elementary",
    "Intermediate",
    "Level 1",
    "Level 2",
    "Level 3",
    "Performance I",
    "Performance II",
    "Performance III",
    "Starters",
    "Masters",
    "Novice",
    "Senior",
    "Veterans",
    "Local Qualifier",
  ],
  trialClasses: [
    { name: "biathlon", label: "Biathlon" },
    {
      name: "biathlonMCPerformanceJumpers",
      label: "Biathlon MC Performance Jumpers",
    },
    { name: "biathlonMCJumpers", label: "Biathlon MC Jumpers" },
    {
      name: "biathlonMCStandardAgility",
      label: "Biathlon MC Standard Agility",
    },
    {
      name: "biathlonMCPerfomanceStandard",
      label: "Biathlon MC Performance Standard",
    },
    { name: "fast", label: "FAST" },
    { name: "gamblers", label: "Gamblers" },
    { name: "grandPrix", label: "Grand Prix" },
    { name: "grandPrixOfDogAgility", label: "Grand Prix of Dog Agility" },
    { name: "performanceGrandPrix", label: "Performance Grand Prix" },
    { name: "jackPot", label: "Jack Pot" },
    { name: "joker", label: "Joker" },
    { name: "jumpers", label: "Jumpers" },
    { name: "jumpersWithWeaves", label: "Jumpers With Weaves" },
    { name: "mastersChallengeBiathlon", label: "Masters Challenge Biathlon" },
    {
      name: "mastersChallengeBiathlonStandard",
      label: "Masters Challenge Biathlon Standard",
    },
    { name: "powerAndSpeed", label: "Power and Speed" },
    { name: "snooker", label: "Snooker" },
    { name: "speedJumping", label: "Speed Jumping" },
    { name: "standardAgility", label: "Standard Agility" },
    { name: "steeplechase", label: "Steeplechase" },
    { name: "teamSnooker", label: "Team Snooker" },
    { name: "teamPairsRelay", label: "Team Pairs / Relay" },
    { name: "timeToBeat", label: "Time to Beat" },
  ],
};
