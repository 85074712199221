<template>
  <base-spinner v-show="isLoading"></base-spinner>
  <div class="formWrapper" v-show="!isLoading">
    <h1>Add Dog</h1>
    <el-form
      ref="addDogForm"
      :model="addDogForm"
      :rules="formRules"
      label-position="top"
    >
      <el-form-item label="Dog Name" prop="formalName">
        <el-input v-model="addDogForm.formalName" :clearable="true"></el-input>
      </el-form-item>
      <section>
        <el-form-item label="Call Name" prop="callName" style="width: 48%">
          <el-input v-model="addDogForm.callName" :clearable="true"></el-input>
        </el-form-item>
        <el-form-item label="Breed" prop="breed" style="width: 48%">
          <el-input v-model="addDogForm.breed" :clearable="true"></el-input>
        </el-form-item>
      </section>
      <section class="border-bottom">
        <el-form-item label="DOB" prop="dob" style="width: 48%">
          <el-input
            v-maska="'##/##/####'"
            v-model="addDogForm.dob"
            :clearable="true"
            placeholder="mm/dd/yyyy"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="Height At Withers"
          prop="heightAtWithers"
          style="width: 48%"
        >
          <el-input v-model="addDogForm.heightAtWithers" :clearable="true"
            ><template #append>inches</template>
          </el-input>
        </el-form-item>
      </section>
      <div class="image-upload border-bottom">
        <el-form-item
          label="Upload Dog Image"
          v-if="addOrEdit === 'add' || removeDogImage"
        >
          <ImageUpload ref="imageUpload" @imageURL="addImageURL" />
        </el-form-item>
        <el-form-item
          label="Uploaded Dog Image"
          v-else-if="addOrEdit === 'edit' && !removeDogImage"
        >
          <div
            class="dog-image"
            @mouseenter="removeFocused"
            @mouseleave="removeNotFocused"
          >
            <div class="dog-image-remove">
              <img :src="dogImageToEdit" alt="Dog Image" />
              <font-awesome-icon
                :icon="['far', 'trash-alt']"
                @click.prevent="removeImage()"
                class="remove-dog-image-icon"
                :class="{ removeFocused: removeIsFocused }"
              ></font-awesome-icon>
            </div>
          </div>
        </el-form-item>
        <p class="image-upload-subtext">
          For best results image should be 500px by 500px.
        </p>
      </div>

      <div class="dynamic-inputs">
        <p class="form-label">Jump Heights</p>
        <el-form-item prop="jumpHeight">
          <div class="default-jump-height">
            <p class="form-info-label">Default Jump Height</p>
            <el-input
              v-model="addDogForm.jumpHeight"
              :clearable="true"
              style="width: 35%"
              ><template #append>inches</template></el-input
            >
          </div>
        </el-form-item>
        <el-form-item
          v-for="(jumpHeight, index) in addDogForm.venueJumpHeights"
          :key="jumpHeight.itemId"
          :prop="`venueJumpHeights.${index}.value`"
        >
          <section>
            <el-select
              v-model="jumpHeight.venue"
              filterable
              placeholder="Select Venue"
            >
              <el-option
                v-for="item in sanctionBodies"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <el-input
              class="info-input"
              v-model="jumpHeight.jumpHeight"
              placeholder="Jump Height"
              ><template #append>inches</template></el-input
            >
            <font-awesome-icon
              :icon="['fa', 'minus-circle']"
              @click.prevent="removeItem(jumpHeight, 'venueJumpHeights')"
            ></font-awesome-icon>
          </section>
        </el-form-item>
        <div class="add-button">
          <base-button
            color="primary"
            @click.prevent="addItem('venueJumpHeights')"
            >Add JumpHeight</base-button
          >
        </div>
      </div>

      <div class="dynamic-inputs">
        <p class="form-label">Titles</p>
        <el-form-item
          v-for="(title, index) in addDogForm.titles"
          :key="title.itemId"
          :prop="`titles.${index}.value`"
        >
          <section>
            <el-input
              class="classification-input title-earned"
              v-model="title.title"
              placeholder="Title"
            ></el-input>
            <el-select
              v-model="title.venue"
              filterable
              placeholder="Select Venue"
            >
              <el-option
                v-for="item in sanctionBodies"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <el-form-item
              :prop="'titles[' + index + '].dateEarned'"
              :rules="formRules.dateEarned"
            >
              <el-input
                class="info-input date-earned"
                v-model="title.dateEarned"
                placeholder="mm/dd/yyyy"
                v-maska="'##/##/####'"
              ></el-input>
            </el-form-item>
            <font-awesome-icon
              :icon="['fa', 'minus-circle']"
              @click.prevent="removeItem(title, 'titles')"
            ></font-awesome-icon>
          </section>
        </el-form-item>
        <div class="add-button">
          <base-button color="primary" @click.prevent="addItem('titles')"
            >Add Title</base-button
          >
        </div>
      </div>

      <div class="dynamic-inputs">
        <p class="form-label">Registrations</p>
        <el-form-item
          v-for="(registration, index) in addDogForm.registrations"
          :key="registration.itemId"
          :prop="`registrations.${index}.value`"
        >
          <section>
            <el-select
              v-model="registration.venue"
              filterable
              placeholder="Select Venue"
            >
              <el-option
                v-for="item in sanctionBodies"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
            <el-input
              class="info-input"
              v-model="registration.registrationNumber"
              placeholder="Number"
            ></el-input>
            <font-awesome-icon
              :icon="['fa', 'minus-circle']"
              @click.prevent="removeItem(registration, 'registrations')"
            ></font-awesome-icon>
          </section>
        </el-form-item>
        <div class="add-button">
          <base-button color="primary" @click.prevent="addItem('registrations')"
            >Add Registration</base-button
          >
        </div>
      </div>

      <div class="dynamic-inputs">
        <p class="form-label">Handlers</p>
        <el-form-item
          v-for="(handler, index) in addDogForm.handlers"
          :key="handler.itemId"
          :prop="`handlers.${index}.value`"
        >
          <section>
            <el-input
              class="full-width-input"
              v-model="handler.handlerName"
              placeholder="Handler Name"
            ></el-input>
            <font-awesome-icon
              :icon="['fa', 'minus-circle']"
              @click.prevent="removeItem(handler, 'handlers')"
            ></font-awesome-icon>
          </section>
        </el-form-item>
        <div class="add-button">
          <base-button color="primary" @click.prevent="addItem('handlers')"
            >Add Handler</base-button
          >
        </div>
      </div>

      <div class="buttons">
        <base-button color="tertiary" @click="handleCancel">Cancel</base-button>
        <base-button color="secondary" @click.prevent="saveImage">{{
          getButtonText
        }}</base-button>
      </div>
    </el-form>
  </div>
</template>

<script>
import ImageUpload from "@/ui/ImageUpload";
import { uuid } from "vue-uuid";
import moment from "moment";
import { Dog } from "@/models/dogModel";
import { sanctionBodies } from "../../utils/generalFormData";
import defaultAvatar from "../../assets/defaultAvatar.png";
const envURL = process.env.VUE_APP_ENVURL;

export default {
  props: { addOrEdit: String },
  components: { ImageUpload },
  data() {
    let checkDate = (rule, value, callback) => {
      const isRequired = rule.field === "dob" ? true : false;

      if (value === "") {
        isRequired
          ? callback(new Error("Please input valid date"))
          : callback();
      } else if (value !== "") {
        const dateValid = moment(value, "MM/DD/YYYY", true).isValid();

        !dateValid && callback(new Error("date invalid (ex. 02/15/2022)"));

        dateValid && callback();
      }
    };
    return {
      isLoading: false,
      sanctionBodies: sanctionBodies,
      dogToEdit:
        this.addOrEdit === "edit"
          ? (this.dogToEdit = this.$store.getters["dog/getDog"])
          : undefined,
      dogImageToEdit: this.addOrEdit === "edit" ? this.getDogImage() : "",
      removeIsFocused: false,
      removeDogImage: false,
      addDogForm: {
        formalName: this.dogToEdit ? this.dogToEdit.formalName : "",
        callName: this.dogToEdit ? this.dogToEdit.callName : "",
        breed: this.dogToEdit ? this.dogToEdit.breed : "",
        dob: this.dogToEdit ? this.dogToEdit.dob : "",
        heightAtWithers: this.dogToEdit
          ? this.getHeight("heightAtWithers")
          : "",
        jumpHeight: this.dogToEdit ? this.getHeight("jumpHeight") : "",
        dogImage: this.dogToEdit ? this.dogToEdit.dogImage : "",
        venueJumpHeights:
          this.dogToEdit && this.dogToEdit.venueJumpHeights
            ? this.dogItemsList("venueJumpHeights")
            : [{ itemId: uuid.v4(), venue: "", jumpHeight: "" }],
        titles: this.dogToEdit
          ? this.dogItemsList("titles")
          : [{ itemId: uuid.v4(), title: "", venue: "", dateEarned: "" }],
        registrations: this.dogToEdit
          ? this.dogItemsList("registrations")
          : [{ itemId: uuid.v4(), venue: "", registrationNumber: "" }],
        handlers: this.dogToEdit
          ? this.dogItemsList("handlers")
          : [{ itemId: uuid.v4(), handlerName: "" }],
        updatedBy: "",
      },
      addItems: {
        venueJumpHeights: { itemId: uuid.v4(), venue: "", jumpHeight: "" },
        titles: { itemId: uuid.v4(), title: "", venue: "", dateEarned: "" },
        registrations: { itemId: uuid.v4(), venue: "", registrationNumber: "" },
        handlers: { itemId: uuid.v4(), handlerName: "" },
      },
      formRules: {
        formalName: [
          { required: true, message: "Please enter dog name", trigger: "blur" },
        ],
        callName: [
          {
            required: true,
            message: "Please enter call name",
            trigger: "blur",
          },
        ],
        dob: [
          {
            required: true,
            validator: checkDate,
            trigger: "blur",
          },
        ],
        dateEarned: [
          {
            validator: checkDate,
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    getUserId() {
      return this.$store.getters["user/getUserId"];
    },
    getButtonText() {
      return this.addOrEdit === "add" ? "Add Dog" : "Update Dog";
    },
  },
  watch: {
    getUserId(userId) {
      this.addDogForm.updatedBy = userId;
    },
  },
  methods: {
    addImageURL(imageURL) {
      this.addDogForm.dogImage = imageURL;
      this.submitForm();
    },
    async saveImage() {
      this.isLoading = true;
      if (this.addOrEdit === "add" || this.removeDogImage) {
        const needToSaveImage = this.$refs["imageUpload"].hasImageToSave();
        needToSaveImage
          ? await this.$refs["imageUpload"].handleSaveImage()
          : this.submitForm();
      } else if (this.addOrEdit === "edit" && !this.removeDogImage) {
        this.submitForm();
      }
    },
    submitForm() {
      this.isLoading = true;

      let notificaitonMessage = "No message";

      this.$refs["addDogForm"].validate(async (valid) => {
        if (valid) {
          try {
            await this.checkForEmpty();

            // need to check if this is creating a new result
            // or updating an existing result
            // and use correct route for each so as not to
            // create multiple results for the same dog/class
            if (this.addOrEdit === "add") {
              await this.$store.dispatch(
                "user/addDog",
                new Dog(this.addDogForm)
              );
              notificaitonMessage = `${this.addDogForm.callName} added`;
            }

            if (this.addOrEdit === "edit") {
              await this.$store.dispatch("dog/updateDog", {
                dogId: this.$store.getters["dog/getDog"]._id,
                update: { ...this.addDogForm },
              });

              this.removeDogImage &&
                (await this.$store.dispatch("user/updateUserProfile"));

              notificaitonMessage = `${this.addDogForm.callName} updated`;
            }
          } catch (error) {
            console.log(error);
            this.$message({
              showClose: true,
              message: "Error ADF1000 creating dog",
              type: "error",
              duration: 5000,
            });
          }
        } else {
          this.isLoading = false;

          this.$message({
            showClose: true,
            message: "Make sure all require fields are completed",
            type: "error",
            duration: 5000,
          });
          return false;
        }
        this.resetForm();
        this.$message({
          showClose: true,
          message: notificaitonMessage,
          type: "success",
          duration: 5000,
        });
        this.isLoading = false;

        this.$router.push({ name: "ownerdogs" });
      });
    },
    handleCancel() {
      this.$router.push({ name: "ownerdogs" });
    },
    addItem(itemArray) {
      this.addDogForm[itemArray].push({
        ...this.addItems[itemArray],
      });
    },
    removeItem(item, itemArray) {
      const newItems = this.addDogForm[itemArray].filter((currentItem) => {
        return currentItem !== item;
      });
      this.addDogForm[itemArray] = newItems;
    },
    checkForEmpty() {
      const itemsToCheck = [
        { arrayItem: "venueJumpHeights", checkItem: "jumpHeight" },
        { arrayItem: "titles", checkItem: "title" },
        { arrayItem: "registrations", checkItem: "venue" },
        { arrayItem: "handlers", checkItem: "handlerName" },
      ];
      for (const element of itemsToCheck) {
        const updatedItems = this.addDogForm[element.arrayItem].filter(
          (currentItem) => {
            return currentItem[element.checkItem] !== "";
          }
        );

        this.addDogForm[element.arrayItem] = updatedItems;
      }
    },
    resetForm() {
      this.$refs["addDogForm"].resetFields();
      this.addDogForm.dogImage = "";
    },
    dogItemsList(whichArray) {
      let arrayList = [];
      const pickItem = {
        venueJumpHeights: { itemId: uuid.v4(), venue: "", jumpHeight: "" },
        titles: { itemId: uuid.v4(), title: "", dateEarned: "" },
        registrations: { itemId: uuid.v4(), venue: "", registrationNumber: "" },
        handlers: { itemId: uuid.v4(), handlerName: "" },
      };

      if (this.dogToEdit[whichArray].length > 0) {
        this.dogToEdit[whichArray].forEach((arrayItem) =>
          arrayList.push(arrayItem)
        );
      } else {
        arrayList.push({ ...pickItem[whichArray] });
      }
      return arrayList;
    },
    getDogImage() {
      return this.dogToEdit.dogImage !== ""
        ? `${envURL}/uploads/${this.$store.getters["dog/getDog"].dogImage}`
        : defaultAvatar;
    },
    getHeight(whichHeight) {
      return this.dogToEdit[whichHeight] !== ""
        ? parseInt(this.dogToEdit[whichHeight], 10)
        : "";
    },
    removeImage() {
      this.removeDogImage = true;
    },
    removeFocused() {
      this.removeIsFocused = true;
    },

    removeNotFocused() {
      this.removeIsFocused = false;
    },
  },
};
</script>

<style lang="scss" scoped>
$iconPosition: calc(50% - 10px);
.formWrapper {
  width: 575px;
  margin: 0 auto;
  padding: 20px 0;

  h1 {
    text-align: center;
  }
}

section {
  display: flex;
  justify-content: space-between;
}

.border-bottom {
  padding-bottom: 20px;
  border-bottom: 1px solid $borderLight;
}

.image-upload {
  padding: 30px 0 10px;
  text-align: center;
}

.form-label {
  margin: 10px 0;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  line-height: 18px;
  color: $tertiary;
}

.dynamic-inputs {
  padding: 30px 0;
  border-bottom: 1px solid $borderLight;

  .full-width-input {
    width: 95%;
  }

  .classification-input {
    width: 55%;
  }

  .info-input {
    width: 35%;
  }

  svg {
    width: 3%;
    margin: 13px 0 0 10px;
    color: $tertiary-75;

    :hover {
      cursor: pointer;
      color: $secondary;
    }
  }

  button {
    text-align: right;
  }

  .add-button {
    display: flex;
    justify-content: flex-end;
  }
}

.buttons {
  padding-top: 20px;
  text-align: center;

  button {
    margin-left: 20px;
  }
}

.image-upload-subtext {
  font-size: 0.9rem;
  color: $textSecondary;
}

.dog-image {
  position: relative;
  width: 148px;
  height: 148px;
  margin: 0 auto;

  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }

  &:hover {
    background: black;

    img {
      opacity: 0.7;
    }
  }
}

.remove-dog-image-icon {
  position: absolute;
  top: $iconPosition;
  left: $iconPosition;
  color: $textWhite;
  font-size: 1.25rem;
  opacity: 0;

  &.removeFocused {
    opacity: 0.85;
  }

  &:hover {
    cursor: pointer;
  }
}

.el-input.title-earned {
  width: 45%;
}

.el-input.date-earned {
  width: 135px;
}

.default-jump-height {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;

  p {
    width: 52%;
    color: #909399;
    line-height: 18px;
    font-size: 18px;
  }
}
</style>
